// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-7-daystodie-js": () => import("./../../../src/pages/7daystodie.js" /* webpackChunkName: "component---src-pages-7-daystodie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-7-daystodie-index-js": () => import("./../../../src/templates/7daystodie/index.js" /* webpackChunkName: "component---src-templates-7-daystodie-index-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */)
}

